<template>
    <div>
        <main-layout>
            <template v-slot:breadcrumb>Account Details</template>
            <div class="row">
                <div class="col-12 col-md-8 layout-spacing">
                    <div class="widget">
                        
                        <div class="widget-heading">
                            <h5>Profile info</h5>
                        </div>
                        <div class="widget-content">
                            <form @submit.prevent="updateProfile">
                                <div class="form-row" v-if="user.account_type == 'individual'">
                                    <div class="col-12 col-md form-group">
                                        <label for="Firstname">Firstname</label>
                                        <input type="text" name="firstname" id="firstname" class="form-control" placeholder="First name" v-model="firstname" required>
                                    </div>
                                    <div class="col-12 col-md form-group">
                                        <label for="Lastname">Lastname</label>
                                        <input type="text" name="lastname" id="lastname" class="form-control" placeholder="Last name" v-model="lastname" required>
                                    </div>
                                </div>
                                <div class="form-row" v-if="user.account_type == 'business'">
                                    <div class="col form-group">
                                        <label for="Business name">Business name</label>
                                        <input type="text" name="business_name" id="business_name" class="form-control" required v-model="business_name" placeholder="Business name">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-12 col-md form-group">
                                        <label for="Email">Email</label>
                                        <input type="email" name="email" id="email" class="form-control" placeholder="Email@example.com" v-model="email" required>
                                    </div>
                                    <div class="col-12 col-md form-group">
                                        <label for="Phone number">Phone</label>
                                        <input type="tel" name="phone" id="phone" class="form-control" placeholder="+23480123456789" v-model="phone" required>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-primary">Update</button>
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="widget">
                        <div class="widget-heading">
                            <h5>Change password</h5>
                        </div>
                        <div class="widget-content">
                            <form action="">
                                <div class="form-group">
                                    <label for="Current Password">Current password</label>
                                    <input type="password" name="current_password" id="current_password" class="form-control" placeholder="Type current password">
                                </div>
                                <div class="form-group">
                                    <label for="New Password">New password</label>
                                    <input type="password" name="new_password" id="new_password" class="form-control" placeholder="Type new desired password">
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-warning">Change password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="widget mt-3">
                        <div class="widget-heading">
                            <h5>Developer</h5>
                        </div>
                        <div class="widget-content">
                            <form action="">
                                <div v-if="user.live_secret_key == null || user.test_secret_key == null">
                                    <button class="btn btn-info" type="button" @click="becomeDeveloper">Become a developer</button>
                                </div>
                                <div v-else>
                                    <p class="font-weight-bold">Api Configuration Keys</p>
                                    <div class="form-group">
                                        <label for="Live key">Live Secret Key</label>
                                        <div class="input-group">
                                            <input type="text" name="live_key" id="live_api_key" class="form-control" v-model="user.live_secret_key" readonly onclick="this.setSelectionRange(0, this.value.length)">
                                            <div class="input-group-append">
                                            <button class="btn btn-info" type="button" @click="copyApiKey('#live_api_key')" title="Copy live key"><i class="fa fa-copy"></i><span class="sr-only">Copy</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="Test key">Test Secret Key</label>
                                        <div class="input-group">
                                            <input type="text" name="test_key" id="test_api_key" class="form-control" v-model="user.test_secret_key" readonly onclick="this.setSelectionRange(0, this.value.length)">
                                            <div class="input-group-append">
                                                <button class="btn btn-info" type="button" @click="copyApiKey('#test_api_key')" title="Copy test key"><i class="fa fa-copy"></i><span class="sr-only">Copy</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4 layout-spacing">
                    <div class="widget">
                        <div class="widget-heading">
                            <h5>Address</h5>
                        </div>
                        <div class="widget-content">
                            <form action="">
                                <div class="form-row">
                                    <div class="col form-group">
                                        <textarea name="address" id="address" v-model="address" class="form-control"></textarea>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-primary">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- <div class="row">
                <div class="col-md-4">
                    <div class="widget">
                        <div class="widget-heading">
                            <h5>Change password</h5>
                        </div>
                        <div class="widget-content">
                            <form action="">
                                <div class="form-group">
                                    <label for="Current Password">Current password</label>
                                    <input type="password" name="current_password" id="current_password" class="form-control" placeholder="Type current password">
                                </div>
                                <div class="form-group">
                                    <label for="New Password">New password</label>
                                    <input type="password" name="new_password" id="new_password" class="form-control" placeholder="Type new desired password">
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-warning">Change password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> -->
        </main-layout>
    </div>
</template>
<script>
import { useToast } from 'vue-toastification'
import Auth from '../../helpers/auth'
import MainLayout from '../../layouts/MainLayout.vue'
import instance from '../../presenter/api/network'
export default {
    components: {MainLayout},
    data(){
        return {
            firstname: "",
            lastname: "",
            business_name: "",
            email: "",
            phone: "",
            address: "",
            user: {
                // live_secret_key: "our_awesome_live_secret_key",
                // test_secret_key: "our_understanding_test_secret_key"
            }
        }
    },
    methods: {
        getCustomerInfo(){
            this.$root.startRequestLoader();
            instance.get(`customer/profile`).then(res => {
                let data = res.data.data;
                this.firstname = data.first_name;
                this.lastname = data.last_name;
                this.business_name = data.business_name;
                this.email = data.email;
                this.address = data.address;
                this.user = res.data.data;
            }).catch(err => {
                console.log(err);
                useToast().error("Unable to fetch your profile details at this time. Please refresh or try later");
            }).finally(() => {
                this.$root.endRequestLoader();
            })
        },
        updateProfile(){
            let data = {
                first_name: this.firstname,
                last_name: this.lastname,
                business_name: this.business_name,
                email: this.email,
                phone: this.phone,
            }

            this.$root.startRequestLoader();
            instance.post('/customer/update-profile', data).then(res => {
                let user = res.data.data;
                Auth.updateUser(user);
                useToast().success("Profile updated successfully");
            }).catch(err => {
                useToast().error("There was an error updating your profile");
            }).finally(() => {
                this.$root.endRequestLoader();
            })
        },
        becomeDeveloper(){
            instance.get("customer/activate-api-access").then(res => {
                // Auth.saveUser(res.data.user);
                useToast().success("Congratulations, you are now a developer")
                
                setTimeout(() => {
                    location.reload()  
                }, 3000);
                
            }).catch(err => {
                useToast().error("Unable to activate your developer account at the moment, please try later")
            })
        },
        copyApiKey (selector) {
            let testingCodeToCopy = document.querySelector(selector)
            testingCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
            testingCodeToCopy.select()

            try {
                var successful = document.execCommand('copy');
                useToast().success("Api key copied to clipboard")
            } catch (err) {
                alert('Oops, unable to copy');
            }

            /* unselect the range */
            window.getSelection().removeAllRanges()
        },
    },
    mounted(){
        let user = Auth.user();
        this.firstname = user.first_name;
        this.lastname = user.last_name;
        this.email = user.email;
        this.phone = user.phone;
        this.address = user.address;
        this.getCustomerInfo();
    }
}
</script>